<template>
  <div>
    <BRow class="mx-2">
      <h5 class="text-black">
        <strong>
          Total Akun Prioritas : {{ totalAccount }}
        </strong>
      </h5>
    </BRow>
    <BRow class="mt-2 justify-content-end">
      <BCol
        md="3"
        class="pl-0"
      >
        <BFormInput
          v-model="search"
          placeholder="Cari nama, email atau no.HP"
          @input="searchData"
        />
      </BCol>
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
        hover
        striped
        no-border-collapse
        :no-local-sorting="true"
        style="min-height: 700px;"
      >
        <!-- Head -->
        <template #head(transaction)="data">
          <div class="flex items-center">
            <span class="mr-[5px]">{{ data.label }}</span>
            <img
              id="transactionHead"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
            >
            <b-popover
              triggers="hover"
              target="transactionHead"
              placement="topleft"
            >
              Bulan ini
            </b-popover>
          </div>
        </template>
        <template #head(retur_ratio)="data">
          <div class="flex items-center">
            <span class="mr-[5px]">{{ data.label }}</span>
            <img
              id="returRationHead"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
            >
            <b-popover
              triggers="hover"
              target="returRationHead"
              placement="topleft"
            >
              Bulan lalu
            </b-popover>
          </div>
        </template>
        <template #head(unread_tickets)="data">
          <div class="flex items-center">
            <span class="mr-[5px]">{{ data.label }}</span>
            <img
              id="unread_ticket"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
            >
            <b-popover
              triggers="hover"
              target="unread_ticket"
              placement="topleft"
            >
              Semua Tiket yang belum dibalas Seller (Perlu Tindak Lanjut)
            </b-popover>
          </div>
        </template>
        <template #head(problem_ratio)="data">
          <div class="flex items-center">
            <span class="mr-[5px]">{{ data.label }}</span>
            <img
              id="propblemRationHead"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
            >
            <b-popover
              triggers="hover"
              target="propblemRationHead"
              placement="topleft"
            >
              Bulan lalu
            </b-popover>
          </div>
        </template>

        <!-- Cell -->
        <template #cell(no)="data">
          <div class="text-nowrap">
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(name)="data">
          <div class="flex items-center text-nowrap">
            <img
              :src="data.item.level_image_url"
              alt="Komerce"
              class="mr-[5px] w-[30px] h-[30px]"
            >
            <div>
              <div :id="`name-${String(data.item.partner_id)}`">
                {{ data.item.name }}
              </div>
              <div class="text-[#828282]">
                {{ data.item.email }}
              </div>
              <b-popover
                triggers="hover"
                :target="`name-${String(data.item.partner_id)}`"
                placement="top"
              >
                {{ data.item.location }}
              </b-popover>
            </div>
          </div>
        </template>
        <template #cell(no_hp)="data">
          <div class="flex items-center">
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/copy.svg"
              alt="Komerce"
              @click="toastCopyResi(data.item.no_hp)"
            >
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
              alt="Komerce"
              @click="handlePhone(data.item.no_hp)"
            >
            <div class="mr-[5px] phoneNumber">
              {{ data.item.no_hp }}
            </div>
          </div>
        </template>
        <template #cell(transaction)="data">
          <div :id="`transaction-${String(data.index)}`" @mouseover="fetchHoverTransaction(data.item.partner_id)" @mouseleave="leaveHover">
            {{ formatNumber(data.item.transaction.total) }}
          </div>
          <b-popover
            triggers="hover"
            :target="`transaction-${String(data.index)}`"
            placement="topleft"
          >
            <b-row class="mb-1 text-popover">
              <b-col cols="12" class="mb-50">
                <span class="text-black">
                  <strong>
                    Komposisi Ekspedisi
                  </strong>
                </span>
              </b-col>
              <b-col cols="12" class="mb-50">
                <span>
                  JNE {{ detailTransactionItem.transaction.expeditions.jne.percentage }}% ({{ formatNumber(detailTransactionItem.transaction.expeditions.jne.value) }})
                </span>
              </b-col>
              <b-col cols="12" class="mb-50">
                <span>
                  SICEPAT {{ detailTransactionItem.transaction.expeditions.sicepat.percentage }}% ({{ formatNumber(detailTransactionItem.transaction.expeditions.sicepat.value) }})
                </span>
              </b-col>
              <b-col cols="12" class="mb-50">
                <span>
                  IDEXPRESS {{ detailTransactionItem.transaction.expeditions.idexpress.percentage }}% ({{ formatNumber(detailTransactionItem.transaction.expeditions.idexpress.value) }})
                </span>
              </b-col>
              <b-col cols="12" class="mb-50">
                <span>
                  SAP {{ detailTransactionItem.transaction.expeditions.sap.percentage }}% ({{ formatNumber(detailTransactionItem.transaction.expeditions.sap.value) }})
                </span>
              </b-col>
            </b-row>
            <b-row class="text-popover">
              <b-col cols="12" class="mb-50">
                <span class="text-black">
                  <strong>
                    Komposisi Pembayaran
                  </strong>
                </span>
              </b-col>
              <b-col cols="12" class="mb-50">
                <span>
                  COD : {{ formatNumber(detailTransactionItem.transaction.payments.cod) }}
                </span>
              </b-col>
              <b-col cols="12" class="mb-50">
                <span>
                  Non-COD : {{ formatNumber(detailTransactionItem.transaction.payments.non_cod) }}
                </span>
              </b-col>
            </b-row>
          </b-popover>
        </template>
        <template #cell(retur_ratio)="data">
          <div :id="`retur-ratio-${String(data.index)}`" :class="getClassColors(data.item)" @mouseover="fetchHoverTransaction(data.item.partner_id)">
            {{ data.value }}
          </div>
          <b-popover
            triggers="hover"
            :target="`retur-ratio-${String(data.index)}`"
            placement="topleft"
          >
            <b-row class="text-popover">
              <b-col v-if="!loadingHover" cols="12" class="mb-50">
                <span class="text-black">
                  <strong>
                    3 Bulan Terakhir
                  </strong>
                </span>
              </b-col>
              <div v-if="!loadingHover" class="mx-1 mb-1">
                <span>
                  {{ detailTransactionItem.retur_ratio.three_months_ago }}%
                </span>
              </div>
              <div v-if="!loadingHover" class="mr-1 mb-1">
                <span>
                  {{ detailTransactionItem.retur_ratio.two_months_ago }}%
                </span>
              </div>
              <div v-if="!loadingHover">
                <span>
                  {{ detailTransactionItem.retur_ratio.last_month }}%
                </span>
              </div>
            </b-row>
          </b-popover>
        </template>
        <template #cell(notes)="data">
          <div
            class="border border-[1px] bg-[#08A0F7] border-[#08A0F7] text-white rounded-md text-center"
            @click="setPartnerId(data.item)"
          >
            Detail
          </div>
        </template>
        <template #head(pickup_activities)>
          <MonthlyPicker
            v-model="filterMonthPickup"
            date-format="MMM YYYY"
            :month-labels="locale.monthNames"
            alignment="center"
            display="true"
          />
          <div class="dateRange">
            <div>1</div>
            <div>4</div>
            <div>7</div>
            <div>10</div>
            <div>13</div>
            <div>16</div>
            <div>19</div>
            <div>22</div>
            <div>25</div>
            <div>28</div>
            <div>31</div>
          </div>
        </template>
        <template #cell(pickup_activities)="data">
          <div class="d-flex justify-content-center align-items-center">
            <div
              v-for="(item, index) in data.item.indicators"
              :key="index"
            >
              <span class="text-black">
                <svg
                  width="20"
                  height="62"
                  viewBox="0 0 20 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-1"
                >
                  <rect
                    v-if="item.indicator === 1"
                    width="20"
                    height="62"
                    fill="#E31A1A"
                  />
                  <rect
                    v-else-if="item.indicator === 2"
                    width="20"
                    height="62"
                    fill="#34A770"
                  />
                  <rect
                    v-else
                    width="20"
                    height="62"
                    fill="#F4F4F4"
                  />
                </svg>
              </span>
            </div>
          </div>
        </template>
      </BTable>
    </BOverlay>
    <ModalNotes
      :partner-id="partnerId"
      :notes="notes"
      :get-list-data="getListData"
    />
  </div>
</template>

<script>
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { LABELDAY, LABELMONTH, END_DATE_OF_MONTH } from '@/libs/filterDate'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import { komshipAxiosIns } from '@/libs/axios'
import MonthlyPicker from 'vue-monthly-picker'
import { configTableSkipper } from './config'
import ModalNotes from './ModalNotes.vue'

export default {
  components: { MonthlyPicker, ModalNotes },
  data() {
    return {
      loading: false,
      search: '',
      fields: configTableSkipper,
      items: [],
      limit: 25,
      offset: 0,
      lastData: false,
      filterMonthPickup: this.$moment(),
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      partnerId: 0,
      notes: '',
      datas: {},
      partnerIdItems: [],
      totalAccount: 0,
      detailTransactionItem: {
        transaction: {
          expeditions: {
            idexpress: {
              percentage: 0,
              value: 0,
            },
            sap: {
              percentage: 0,
              value: 0,
            },
            jne: {
              percentage: 0,
              value: 0,
            },
            sicepat: {
              percentage: 0,
              value: 0,
            },
          },
          payments: {
            cod: 0,
            non_cod: 0,
          },
        },
        retur_ratio: {
          last_month: 0,
          two_months_ago: 0,
          three_months_ago: 0,
          color_status: '',
        },
      },
      itemTransaction: [],
      loadingTransaction: false,
      loadingHover: false,
    }
  },
  computed: {
    sortedArray() {
      return Object.entries(this.datas)
        .map(([key, value]) => ({ key, value }))
        .sort((a, b) => b.value - a.value)
    },
  },
  watch: {
    filterMonthPickup: {
      handler() {
        this.fetchIndicator()
      },
    },
  },
  async created() {
    window.onscroll = () => {
      console.log('innerHeight', window.innerHeight)
      console.log('offset', document.getElementById('scroll').offsetHeight)
      console.log('scrollY', window.scrollY)
      if ((window.innerHeight + window.scrollY) >= document.getElementById('scroll').offsetHeight && !this.loading) {
        this.getNextData()
      }
    }
    await this.getListData()
    await this.fetchTotalAccount()
  },
  methods: {
    async getListData() {
      this.partnerIdItems = []
      this.loading = true
      this.offset = 0
      const url = '/v1/admin/partner-growth-juragan'
      const params = {
        limits: this.limit,
        offset: this.offset,
        pickup_date: END_DATE_OF_MONTH(this.filterMonthPickup),
        search: this.search,
      }
      await komshipAxiosIns.get(url, { params })
        .then(async res => {
          const { data } = res.data
          await data.map(item => this.partnerIdItems.push(item.partner_id))
          this.items = data
          await data.forEach(item => this.fetchDetailTransaction(item.partner_id).then(value => {
            const find = this.items.findIndex(findItem => findItem.partner_id === Number(value.partner_id))
            Object.assign(this.items[find], { color: value.retur_ratio.color_status })
          }))
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
          this.loading = false
          this.fetchIndicator()
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
          this.loading = false
        })
    },
    async getNextData() {
      if (!this.lastData) {
        this.loading = true
        const url = '/v1/admin/partner-growth-juragan'
        const params = {
          limits: this.limit,
          offset: this.offset,
          pickup_date: END_DATE_OF_MONTH(this.filterMonthPickup),
          search: this.search,
        }
        await komshipAxiosIns.get(url, { params })
          .then(async result => {
            const { data } = result.data
            this.items.push(...data)
            await data.map(item => this.partnerIdItems.push(item.partner_id))
            await data.forEach(item => this.fetchDetailTransaction(item.partner_id).then(value => {
              const find = this.items.findIndex(findItem => findItem.partner_id === Number(value.partner_id))
              Object.assign(this.items[find], { color: value.retur_ratio.color_status })
            }))
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
            this.limit += data.length
            this.fetchIndicator()
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            })
          })
      }
    },
    searchData: _.debounce(function search() {
      this.getListData()
    }, 1000),
    handlePhone(phoneNumber) {
      window.open(`https://wa.me/62${phoneNumber.substring(1)}`, '_blank')
    },
    toastCopyResi(number) {
      navigator.clipboard.writeText(number)
      this.$toast(
        {
          component: ToastificationContentVue,
          props: {
            title: `${number} berhasil disalin`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        },
        1000,
      )
    },
    setPartnerId(data) {
      const { partner_id, notes } = data
      this.$bvModal.show('modalNotes')
      // eslint-disable-next-line camelcase
      this.partnerId = partner_id
      this.notes = notes
    },
    async fetchIndicator() {
      const params = {
        partner_id: this.partnerIdItems.join(),
        end_date_pickup: END_DATE_OF_MONTH(this.filterMonthPickup),
      }
      await komshipAxiosIns.get('/v1/admin/partnerGrowth/pickup-history', { params })
        .then(res => {
          const { data } = res.data
          this.items = this.items.map((item, index) => ({
            ...item,
            indicators: data[index],
          }))
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
          this.loading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
          this.loading = false
        })
    },
    fetchTotalAccount() {
      komshipAxiosIns.get('/v1/admin/partner-growth-juragan/card')
        .then(res => {
          this.totalAccount = res.data.data.count_user
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
        })
    },
    formatNumber(n) {
      return String(n).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    fetchHoverTransaction(state) {
      this.loadingHover = true
      this.loadingTransaction = true
      komshipAxiosIns.get(`/v1/admin/partner-growth-juragan/hover?partner_id=${state}`)
        .then(res => {
          this.detailTransactionItem = res.data.data
          this.loadingTransaction = false
          this.loadingHover = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
          this.loadingTransaction = false
          this.loadingHover = false
        })
    },
    async fetchDetailTransaction(state) {
      let result = {}
      await komshipAxiosIns.get(`/v1/admin/partner-growth-juragan/hover?partner_id=${state}`)
        .then(res => {
          result = res.data.data
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
        })
      return result
    },
    getClassColors(state) {
      let result
      if (state.color === 'black') {
        result = 'text-black'
      } else if (state.color === 'green') {
        result = 'text-success'
      } else if (state.color === 'red') {
        result = 'text-primary'
      }
      return result
    },
    leaveHover() {
      this.detailTransactionItem = {
        transaction: {
          expeditions: {
            idexpress: {
              percentage: 0,
              value: 0,
            },
            sap: {
              percentage: 0,
              value: 0,
            },
            jne: {
              percentage: 0,
              value: 0,
            },
            sicepat: {
              percentage: 0,
              value: 0,
            },
          },
          payments: {
            cod: 0,
            non_cod: 0,
          },
        },
        retur_ratio: {
          last_month: 0,
          two_months_ago: 0,
          three_months_ago: 0,
          color_status: '',
        },
      }
    },
  },
}
</script>

<style scoped lang="scss">
.dateRange{
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
  color: #222222;
}
.phoneNumber:hover{
  color: #34A770;
}

.text-popover {
  font-family: "Poppins", sans-serif;
}
</style>
