<template>
  <BModal
    id="modalNotes"
    ref="modalNotes"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    class="modalNotes"
    :centered="true"
  >
    <div class="text-center my-1">
      <p class="text-black font-bold text-start mb-1 text-xl">
        Catatan
      </p>
      <ValidationObserver #default="{ invalid }">
        <BForm @submit.prevent="onFinish">
          <ValidationProvider
            rules="required"
            :custom-messages="messageError"
          >
            <BFormTextarea
              v-model="notes"
              placeholder="Catatan partner"
              rows="3"
              max-rows="6"
              class="mb-2 notesReject"
              size="lg"
            />
          </ValidationProvider>
          <div class="flex justify-center">
            <BButton
              size="md"
              variant="outline-primary"
              class="mr-1"
              style="width: 160.5px"
              @click="$bvModal.hide('modalNotes')"
            >
              Batal
            </BButton>
            <BButton
              size="md"
              variant="primary"
              style="width: 160.5px"
              :disabled="invalid || isLoading || notes === ''"
              type="submit"
              block
            >
              <BSpinner
                v-if="isLoading"
                small
              />
              Simpan
            </BButton>
          </div>
        </BForm>
      </ValidationObserver>
    </div>
  </BModal>
</template>

<script>
import {
  BModal, BButton, BFormTextarea, BForm,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { komshipAxiosIns } from '@/libs/axios'

export default {
  components: {
    BModal, BButton, BFormTextarea, ValidationObserver, ValidationProvider, BForm,
  },
  props: {
    partnerId: {
      type: Number,
      default: required,
    },
    notes: {
      type: String,
      default: required,
    },
    getListData: {
      type: Function,
      default: () => [],
    },
  },
  data() {
    return {
      centered: true,
      isLoading: false,
      messageError: {
        required: 'Tulis alasan kamu menolak claim retur',
      },
      required,
      error: '',
    }
  },
  methods: {
    async onFinish() {
      this.isLoading = true
      const payload = {
        partner_id: this.partnerId,
        notes: this.notes,
      }
      const url = '/v1/admin/partner-growth-juragan/notes'
      await komshipAxiosIns.post(url, payload)
        .then(() => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Berhasil memberikan catatan',
              variant: 'success',
            },
          }, 2000)
          this.getListData()
          this.$bvModal.hide('modalNotes')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Sorry, No Data Claim Retur',
              variant: 'danger',
            },
          }, 2000)
          this.$bvModal.hide('modalNotes')
          this.getListData()
          this.isLoading = false
        })
    },
  },
}
</script>
<style scoped>
.notesReject {
  overflow: hidden !important;
  height: 100px !important;
}
</style>
