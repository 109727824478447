// eslint-disable-next-line import/prefer-default-export
import { IDR } from '@/libs/currency'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'

const smallScreenWidth = 576

export const downloadFields = {
  Nama: 'full_name',
  Email: 'email',
  No_HP: 'no_hp',
  Registrasi: 'registered',
  Pertama_Pickup: 'first_pickup',
}

export const statusOptions = [
  {
    value: '',
    text: 'Register',
  },
  {
    value: 'pickups',
    text: 'Pickups',
  },
  {
    value: 'first_pickup',
    text: '1st Pickup',
  },
  {
    value: 'last_pickup',
    text: 'Last Pickup',
  },
]

export const configTable = [
  {
    key: 'full_name',
    label: 'Nama',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    stickyColumn: !(window.screen.width < smallScreenWidth),
  },
  {
    key: 'contact',
    label: 'Kontak',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
  },
  {
    key: 'partner_id',
    label: 'ID Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle text-center',
  },
  {
    key: 'location',
    label: 'Lokasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '250px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
  },
  {
    key: 'registered',
    label: 'Registrasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '170px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => DAY_MONTH_YEAR(val),
  },
  {
    key: 'email_verified_at',
    label: 'Verifikasi Email',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '170px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => (DAY_MONTH_YEAR(val) ? DAY_MONTH_YEAR(val) : val),
  },
  {
    key: 'is_onboarding',
    label: 'Onboarding',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
  },
  {
    key: 'first_product',
    label: '1st Produk',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '170px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => (val !== 'belum' ? DAY_MONTH_YEAR(val) : 'Belum'),
  },
  {
    key: 'first_order',
    label: '1st Order',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '170px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => (val !== 'belum' ? DAY_MONTH_YEAR(val) : 'Belum'),
  },
  {
    key: 'first_pickup',
    label: '1st Pickup',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '170px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => (val !== 'belum' ? DAY_MONTH_YEAR(val) : 'Belum'),
  },
  {
    key: 'last_pickup',
    label: 'Last Pickup',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '170px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => (val !== 'belum' ? DAY_MONTH_YEAR(val) : 'Belum'),
  },
  {
    key: 'total_order',
    label: 'Total Order',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '170px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
  },
  {
    key: 'total_transaksi',
    label: 'Total Transaksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '170px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => (IDR(val) ? IDR(val) : val),
  },
  {
    key: 'total_ongkir',
    label: 'Total Ongkir',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '170px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => (IDR(val) ? IDR(val) : val),
  },
  {
    key: 'pickup_activities',
    class: 'text-black text-center',
  },
]

export const configTableSkipper = [
  {
    key: 'no',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    stickyColumn: !(window.screen.width < smallScreenWidth),
  },
  {
    key: 'name',
    label: 'Nama',
    thStyle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      color: 'black',
      minWidth: '250px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    stickyColumn: !(window.screen.width < smallScreenWidth),
  },
  {
    key: 'no_hp',
    label: 'No. HP',
    thStyle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      color: 'black',
      minWidth: '170px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
  },
  {
    key: 'join_date',
    label: 'Join Date',
    thStyle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => DAY_MONTH_YEAR(val),
  },
  {
    key: 'last_pickup',
    label: 'Last Pickup',
    thStyle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => DAY_MONTH_YEAR(val),
  },
  {
    key: 'transaction',
    label: 'Transaksi',
    thStyle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      color: 'black',
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
  },
  {
    key: 'retur_ratio',
    label: 'Rasio Retur',
    thStyle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      color: 'black',
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => `${val}%`,
  },
  {
    key: 'problem_ratio',
    label: 'Rasio Problem',
    thStyle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      color: 'black',
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
    formatter: val => `${val}%`,
  },
  {
    key: 'unread_tickets',
    label: 'Tiket tak Terbalas',
    thStyle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      color: 'black',
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
  },
  {
    key: 'notes',
    label: 'Catatan',
    thStyle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      fontSize: '14px',
      color: 'black',
      minWidth: '120px',
    },
    tdClass: 'text-black',
    class: 'p-1 align-middle',
  },
  {
    key: 'pickup_activities',
    class: 'text-black text-center',
  },
]
