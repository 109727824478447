<template>
  <BCard>
    <div class="text-black text-2xl font-semibold ml-1">
      Perkembangan Partner
    </div>
    <BTabs
      class="mt-2"
      content-class="mt-3"
      pills
      active-nav-item-class="rounded-pill"
    >
      <BTab
        title="Semua Akun"
        active
        lazy
        title-item-class="bg-[#F8F8F8] rounded-[25px]"
      >
        <ListCommon />
      </BTab>
      <BTab
        title="Akun Prioritas"
        lazy
        title-item-class="bg-[#F8F8F8] rounded-[25px]"
      >
        <ListSkipper />
      </BTab>
    </BTabs>
  </BCard>
</template>

<script>
import ListCommon from './ListCommon.vue'
import ListSkipper from './ListSkipper.vue'

export default {
  components: { ListCommon, ListSkipper },
}
</script>

<style scoped>

</style>
