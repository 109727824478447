<template>
  <div>
    <BRow class="mt-2 justify-content-end">
      <BCol md="auto">
        <DateRangePicker
          ref="picker"
          v-model="dateRange"
          :locale-data="locale"
          :ranges="ranges"
          :opens="'left'"
        >
          <template v-slot:input="picker">
            <div
              class="d-flex justify-content-between align-items-center"
            >
              <span
                class="mr-2"
              >{{ DAY_MONTH_YEAR(picker.startDate) }} -
                {{ DAY_MONTH_YEAR(picker.endDate) }}</span>
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                alt="Komerce"
              >
            </div>
          </template>
        </DateRangePicker>
      </BCol>
      <BCol
        md="auto"
        class="pl-0"
      >
        <BFormSelect
          v-model="status"
          :options="statusOptions"
          placeholder="Pilih Status"
        />
      </BCol>
      <BCol
        md="auto"
        class="pl-0"
      >
        <BButton
          variant="outline-secondary"
          class="growth__exportData"
          size="md"
        >
          <Excel
            :data="excelData"
            :fields="downloadFields"
            :name="documentName('Perkembangan Partner')"
          >
            Export Data
          </Excel>
        </BButton>
      </BCol>
      <BCol
        md="auto"
        class="pl-0"
      >
        <BFormInput
          v-model="search"
          placeholder="Cari"
          @input="searchData"
        />
      </BCol>
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
        hover
        striped
        :no-local-sorting="true"
      >
        <template #cell(full_name)="data">
          <div class="text-black">
            {{ data.item.full_name }}
          </div>
          <div>{{ data.item.email }}</div>
        </template>
        <template #cell(contact)="data">
          <div class="flex items-center">
            <div class="mr-[5px]">
              {{ data.item.no_hp }}
            </div>
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/copy.svg"
              alt="Komerce"
              @click="toastCopyResi(data.item.no_hp)"
            >
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
              alt="Komerce"
              @click="handlePhone(data.item.no_hp)"
            >
          </div>
        </template>
        <template #cell(is_onboarding)="data">
          <div :class="data.item.is_onboarding === 1 ? 'text-[#28c76f]' : 'text-[#ea5455]'">
            {{ data.item.is_onboarding === 1 ? 'Selesai' : 'Belum' }}
          </div>
        </template>
        <template #head(pickup_activities)>
          <MonthlyPicker
            v-model="filterMonthPickup"
            date-format="MMM YYYY"
            :month-labels="locale.monthNames"
            alignment="center"
            display="true"
          />
          <div class="dateRange">
            <div>1</div>
            <div>4</div>
            <div>7</div>
            <div>10</div>
            <div>13</div>
            <div>16</div>
            <div>19</div>
            <div>22</div>
            <div>25</div>
            <div>28</div>
            <div>31</div>
          </div>
        </template>
        <template #cell(pickup_activities)="data">
          <div class="d-flex justify-content-center align-items-center">
            <div
              v-for="(item, index) in data.item.indicators"
              :key="index"
            >
              <span class="text-black">
                <svg
                  width="20"
                  height="62"
                  viewBox="0 0 20 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-1"
                >
                  <rect
                    v-if="item.indicator === 1"
                    width="20"
                    height="62"
                    fill="#E31A1A"
                  />
                  <rect
                    v-else-if="item.indicator === 2"
                    width="20"
                    height="62"
                    fill="#34A770"
                  />
                  <rect
                    v-else
                    width="20"
                    height="62"
                    fill="#F4F4F4"
                  />
                </svg>
              </span>
            </div>
          </div>
        </template>
      </BTable>
      <BRow>
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div class="bg-light d-flex justify-content-center align-items-center p-50 rounded">
            <span class="text-black mr-50"> List per halaman: </span>
            <BButton
              v-for="page in optionsPage"
              :key="page"
              class="btn-icon"
              size="sm"
              :variant="totalPerPage === page ? 'primary' : 'light'"
              @click="setPerPage(page)"
            >
              {{ page }}
            </BButton>
          </div>

          <BPagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="totalPerPage"
            first-number
            last-number
            class="pagination-primary"
          >
            <template #prev-text>
              <feather-icon
                size="18"
                icon="ChevronLeftIcon"
              />
            </template>
            <template #next-text>
              <feather-icon
                size="18"
                icon="ChevronRightIcon"
              />
            </template>
          </BPagination>
        </BCol>
      </BRow>
    </BOverlay>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  DAY_MONTH_YEAR,
  END_FULL_DATE, LABELDAY, LABELMONTH, START_FULL_DATE,
  START_FULL_MONTH, END_FULL_MONTH, END_DATE_OF_MONTH,
} from '@/libs/filterDate'
import {
  firstDateOfMonth, komshipDate, last30, last60, last7, today,
} from '@/store/helpers'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import { komshipAxiosIns } from '@/libs/axios'
import Excel from 'vue-json-excel'
import MonthlyPicker from 'vue-monthly-picker'
import { configTable, statusOptions, downloadFields } from './config'

const smallScreenWidth = 576

export default {
  components: { DateRangePicker, Excel, MonthlyPicker },
  data() {
    return {
      loading: false,
      status: '',
      search: '',
      statusOptions,
      dateRange: {
        startDate: last30,
        endDate: today,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        '7 Hari': [last7, today],
        '30 Hari terakhir': [last30, today],
        '2 Bulan Terakhir': [last60, today],
        'Bulan ini': [firstDateOfMonth, today],
        'Semua ': [komshipDate, today],
      },
      fields: configTable,
      items: [],
      firstDateOfMonth,
      komshipDate,
      last30,
      last7,
      today,
      last60,
      START_FULL_DATE,
      excelData: [],
      downloadFields,
      filterMonthPickup: this.$moment(),
      currentPage: 1,
      totalRows: 0,
      optionsPage: [50, 100, 200, 500],
      totalPerPage: 50,
      DAY_MONTH_YEAR,
      partnerIdItems: [],
    }
  },
  computed: {
    isStickyHeader() {
      return !(window.screen.width < smallScreenWidth)
    },
  },
  watch: {
    dateRange: {
      handler() {
        this.getListData()
      },
    },
    status: {
      handler() {
        this.getListData()
      },
    },
    filterMonthPickup: {
      handler() {
        this.fetchIndicator()
      },
    },
    currentPage: {
      handler() {
        this.getListData()
      },
    },
  },
  created() {
    this.getListData()
  },
  methods: {
    async getListData() {
      this.partnerIdItems = []
      this.loading = true
      const url = '/v1/admin/partnerGrowth'
      const params = {
        start_date: START_FULL_DATE(this.dateRange.startDate),
        end_date: END_FULL_DATE(this.dateRange.endDate),
        start_date_pickup: START_FULL_MONTH(this.filterMonthPickup),
        end_date_pickup: END_DATE_OF_MONTH(this.filterMonthPickup),
        total_per_page: this.totalPerPage,
        page: this.currentPage,
        search: this.search,
        filter: this.status,
      }
      await komshipAxiosIns.get(url, { params })
        .then(async res => {
          const { data, total } = res.data.data
          this.items = data
          this.loading = false
          this.totalRows = total
          this.excelData = data
          await data.map(item => this.partnerIdItems.push(item.partner_id))
          this.fetchIndicator()
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
          this.loading = false
        })
    },
    searchData: _.debounce(function search() {
      this.getListData()
    }, 1000),
    documentName(name) {
      return `${name + DAY_MONTH_YEAR(this.dateRange.startDate)} - ${DAY_MONTH_YEAR(this.dateRange.endDate)}`
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.getListData()
    },
    handlePhone(phoneNumber) {
      window.open(`https://wa.me/62${phoneNumber.substring(1)}`, '_blank')
    },
    toastCopyResi(number) {
      navigator.clipboard.writeText(number)
      this.$toast(
        {
          component: ToastificationContentVue,
          props: {
            title: `${number} berhasil disalin`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        },
        1000,
      )
    },
    async fetchIndicator() {
      const params = {
        partner_id: this.partnerIdItems.join(),
        end_date_pickup: END_DATE_OF_MONTH(this.filterMonthPickup),
      }
      await komshipAxiosIns.get('/v1/admin/partnerGrowth/pickup-history', { params })
        .then(res => {
          const { data } = res.data
          this.items = this.items.map((item, index) => ({
            ...item,
            indicators: data[index],
          }))
          this.loading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, { timeout: 1000 })
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.dateRange{
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
  color: #222222;
}
</style>
